import { get, isObject } from "lodash";
import { joinURL, withQuery } from "ufo";

export function getUrl<T extends [...string[]]>(
  base: string,
  ...paths: T
): string;
export function getUrl<T extends [...string[], Record<string, string>]>(
  base: string,
  ...paths: T
): string;
export function getUrl(base: string, ...paths: any[]): string {
  const params = paths.pop();

  let url = joinURL(base, ...paths);

  if (isObject(params)) {
    url = withQuery(url, params as any);
  } else {
    url = joinURL(url, params);
  }

  return url;
}

export const onRouteChange = (cb: { (fullPath: string): void }) => {
  const route = useRoute();
  return watch(() => route.fullPath, cb);
};

export const serverUrl = (...paths: string[]) => {
  const config = useRuntimeConfig();

  if (get(paths, 0) == "api" && get(paths, 1) == "sales") {
    paths = paths.slice(2);
    return joinURL(config.public.server.listingApi, ...paths);
  }

  return joinURL(config.public.server.url, ...paths);
};

export const serverApiUrl = (...paths: string[]) => {
  return serverUrl("api", ...paths);
};
